import React from 'react';

const { environment } = window.config;

const whitelistedEnvs = [
  'AvaTrade',
  'BlackStone',
  'BourseTrade',
  'CoreSpreads',
  'CSAustralia',
  'EagleGlobalMarkets',
  'FinancialSpreads',
  'TradeDirect365',
  'ICMCapital',
  'OneTradingMarkets',
  'Dzengi',
  'OrbitInvest',
  'GCCBrokers',
  'VCGMarkets',
  'PolarisMarkets',
  'AAAFX',
];

const defaultSmall = [
  'FinancialSpreads',
  'TradeDirect365',
];

let logoFullUrl = 'assets/images/logo-full.svg';
let logoSmallUrl = 'assets/images/logo-icon.svg';
const logingLogo = `assets/images/environments/${environment}/logo-login.png`;

// remove this once when we get logos for all environements 
if (whitelistedEnvs.includes(environment)) {
  logoFullUrl = `assets/images/environments/${environment}/logo-full.png`;
  logoSmallUrl = `assets/images/environments/${environment}/logo-icon.png`;

  if (defaultSmall.includes(environment)) {
    logoSmallUrl = null;
  }
}

export const LogoFull = () => <div className="biq-nav__logo"><img alt="logo" src={logoFullUrl} /></div>;
export const LogoSmall = () => logoSmallUrl && <img alt="logo" src={logoSmallUrl} />;
export const LoginLogo = () => <img alt="login-logo" src={logingLogo} />;
