import { getDjangoApi } from '../../../logic/services/api-factory';

const selectFieldProps = ({ key, label, choices, ...rest }) => ({
  ...rest,
  type: 'select',
  id: key,
  name: key,
  searchable: true,
  placeholder: label,
  label,
  options: choices,
  valueKey: 'value',
  labelKey: 'display_name',
  joinValues: true,
});

const asyncFieldProps = ({
  key,
  label,
  list_url,
  display_field,
  key_field,
}) => {
  const loadOptions = (input = '') => {
    const uri = list_url.replace('/backoffice/api/', '');
    return getDjangoApi(uri)
      .list({ [display_field]: input })
      .then(res => res.data.results);
  };

  return {
    id: key,
    name: key,
    type: 'select',
    label,
    placeholder: label,
    loadOptions,
    async: true,
    debounceInterval: 500,
    joinValues: true,
    labelKey: display_field || 'display_name',
    valueKey: key_field || 'value',
  };
};

const numberFieldProps = ({ key, label }) => ({
  type: 'number',
  id: key,
  name: key,
  placeholder: label,
  label,
});

const dateFieldProps = ({ key, label }) => ({
  type: 'date',
  id: key,
  name: key,
  placeholder: label,
  label,
});

const datetimeFieldProps = ({ key, label }) => ({
  type: 'datetime',
  id: key,
  label,
});

const textFieldProps = ({ key, label }) => ({
  type: 'text',
  id: key,
  name: key,
  placeholder: label,
  label,
});

const textAreaFieldProps = ({ key, label }) => ({
  type: 'textarea',
  id: key,
  name: key,
  placeholder: label,
  label,
});

const booleanFieldProps = ({ key, label }) => ({
  type: 'checkbox',
  id: key,
  name: key,
  label,
});

const nestedObjectProps = ({ key, label, children }) => ({
  id: key,
  label,
  name: key,
  children: children.fields.map(fieldPropsResolver),
});

const fileUploadFieldProps = ({ key, label }) => ({
  type: 'file',
  id: key,
  label,
  name: key,
});

function fieldPropsResolver(field) {
  switch (field.type) {
    case 'field':
    case 'choice':
      return selectFieldProps(field);
    case 'nested object':
      return nestedObjectProps(field);
    case 'nested relation':
      return field.list_url ? asyncFieldProps(field) : selectFieldProps(field);
    case 'decimal':
      return numberFieldProps(field);
    case 'date':
      return dateFieldProps(field);
    case 'datetime':
      return datetimeFieldProps(field);
    case 'boolean':
      return booleanFieldProps(field);
    case 'textarea':
      return textAreaFieldProps(field);
    case 'file upload':
      return fileUploadFieldProps(field);    
    default:
      return textFieldProps(field);
  }
}

export default fieldPropsResolver;
