import React from 'react';
import { string, object, func, oneOfType, bool, number } from 'prop-types';
import { Pill, InfoBox, Button, Code } from 'tc-biq-design-system';

import { hasAccess } from '../../../../../../logic/services/acl';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import If from '../../../../../../components/If';
import ReportAccordion from './ReportAccordion';

const env = window.config.environment;

const propTypes = {
  type: string,
  status: string,
  result: string,
  error: oneOfType([string, object]),
  breakdown: object,
  documentId: number,
  checkInProgress: bool,
  revalidate: func,
  checkStatus: number,
};

const defaultProps = {
  type: undefined,
  status: undefined,
  result: undefined,
  error: null,
  breakdown: null,
  documentId: undefined,
  checkInProgress: false,
  revalidate: func,
  checkStatus: undefined,
};

const getStatusClass = (result, error) => {
  if (!result || result === 'unidentified' || error) return 'status04'; 
  if (result === 'consider') return 'status03';
  return 'status01';
};

const getErrorMessage = error => (typeof error === 'object' && error.message) || error;

const getInfoFromCheckStatus = (status) => {
  switch (status) {
    case 1:
      return {
        text: 'Pending',
        type: 'status03',
      }; 
    case 2:
      return {
        text: 'Approved',
        type: 'status01',
      };
    case 3:
      return {
        text: 'Rejected',
        type: 'status04',
      };
    case 4:
      return {
        text: 'Failed',
        type: 'status04',
      };
    default:
      return {
        text: 'Error',
        type: 'status04',
      };
  }
};

const text = {
  ERROR: gettext('Error'),
  REVALIDATE: gettext('Revalidate'),
  ONFIDO_ERROR: gettext('Onfido error'),
};

// eslint-disable-next-line
const Report = ({ 
  type, 
  status, 
  result, 
  error, 
  breakdown, 
  documentId, 
  checkInProgress, 
  revalidate,
  checkStatus, 
  // eslint-disable-next-line react/prop-types
  report,
}) => (

  <div style={{ position: 'relative' }}>
    <h3 className="tc-heading-m text-neutral-900 biq-onfido-reports__report">{type}</h3>
    <span className="biq-onfido-reports__status-pill">
      {(env === 'ICMCapital' || env === 'VCGMarkets' || env === 'FXView' || env === 'AAAFX') && <Pill type={getInfoFromCheckStatus(checkStatus).type}>{getInfoFromCheckStatus(checkStatus).text}</Pill>}
      {env !== 'ICMCapital' && env !== 'VCGMarkets' && env !== 'FXView' && env !== 'AAAFX' && <Pill type={getStatusClass(result, error)}>{status || text.ERROR}</Pill>}
    </span>

    <If condition={hasAccess('documents.start_check.*')}>
      <span className="biq-onfido-reports__revalidate">
        {env !== 'VCGMarkets' && env !== 'FXView' && env !== 'AAAFX' && (
        <Button loading={checkInProgress} disabled={checkInProgress} color="transparent" icon="Pending" onClick={() => revalidate(documentId)}>
          {text.REVALIDATE}
        </Button>
        )}
      </span>
    </If>

    {!!error && (
      <InfoBox icon="KYC" type="error" header={text.ONFIDO_ERROR}>
        <span className="text-neutral-900">{getErrorMessage(error)}</span>
      </InfoBox>
    )}

    {_.map(breakdown, (check, name) => <ReportAccordion key={name} name={name} check={check} />)}
    
    {(env === 'ICMCapital' || env === 'VCGMarkets' || env === 'FXView' || env === 'AAAFX') && !Object.keys(breakdown || {}).length && <Code data={report} />}
  </div>
);

Report.propTypes = propTypes;
Report.defaultProps = defaultProps;
export default Report;
