/* eslint-disable no-nested-ternary */
import OnfidoReports from './OnfidoReports';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const ENV = window.config.environment;

export default {
  Component: () => OnfidoReports,
  label: (ENV === 'VCGMarkets' || ENV === 'FXView' || ENV === 'AAAFX') ? gettext('Sumsub Reports') : ENV === 'ICMCapital' ? gettext('GBG Reports') : gettext('Onfido Reports'),
  id: (ENV === 'VCGMarkets' || ENV === 'FXView' || ENV === 'AAAFX') ? 'sumsub-reports' : ENV === 'ICMCapital' ? 'gbg-reports' : 'onfido-reports',
  visibilityFn: (hasAccess, isFeatureEnabledFilter, isIb) => hasAccess('documents.raw_check_result.*') 
    && hasAccess('user.documents.list')
    && isFeatureEnabledFilter()('ONFIDO') 
    && !isIb,
};
