import React, { Component } from 'react';

import withFormSidepanel from '../../../../../../components/FormSidepanel';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { formatRequestData } from '../../../../../../components/form/logic/utils';

export const SIDEPANEL_ID = 'EMAIL_ALERTS_FORM_SIDEPANEL';
export const FORM_ID = 'EMAIL_ALERTS_FORM';

const apiUrl = 'settings/bo_notifications';

const text = {
  CREATE: gettext('Add New Email'),
  EDIT: gettext('Update Email'),
  SUCCESS: {
    create: gettext('Email successfully added'),
    edit: gettext('Email successfully updated'),
  },
};

class EmailAlertsSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
    this.whiteLabelEnabled = isFeatureEnabled()('WHITELABEL');
  }

  getRequestPayload = () => (values) => {
    const parsed = formatRequestData(values);
    if (this.whiteLabelEnabled) {
      return {
        ...parsed,
        whitelabel: values.whitelabel && values.whitelabel.name,
      };
    }
    
    return parsed;
  }

  prepareRowData = () => (data) => {
    const row = { ...data };

    if (this.whiteLabelEnabled) {
      row.whitelabel = _.get(row, 'whitelabel.name');
    }
    
    return row;
  }

  getFormConfig = () => ({
    excludeFields: this.whiteLabelEnabled ? [] : ['whitelabel'],
  });

  getApi = () => getDjangoApi(apiUrl)

  render() {
    const { FormSidepanel, getFormConfig, getApi, getRequestPayload, prepareRowData } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          getApi,
          text,
          getFormConfig,
          getRequestPayload,
          prepareRowData,
        }}
      />
    );
  }
}

export default EmailAlertsSidepanel;
