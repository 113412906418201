export default {
  AvaTrade: 'AvaTrade',
  BlackStone: 'BlackStone',
  BourseTrade: 'BourseTrade',
  CoreSpreads: 'CoreSpreads',
  CoinZero: 'CoinZero',
  CFTest: 'CFTest',
  CFMarkets: 'CFMarkets',
  CSAustralia: 'CSAustralia',
  EagleGlobalMarkets: 'EagleGlobalMarkets',
  FinancialSpreads: 'FinancialSpreads',
  GoMarkets: 'GoMarkets',
  ICMCapital: 'ICMCapital',
  ICMCapitalStage: 'ICMCapitalStage',
  ICMCapitalBeta: 'ICMCapitalBeta',
  Internal: 'Internal',
  ISTMarkets: 'ISTMarkets',
  NetLeaders: 'NetLeaders',
  Oxdfx: 'Oxdfx',
  OneTradingMarkets: 'OneTradingMarkets',
  PipIndex: 'PipIndex',
  PriceMarkets: 'PriceMarkets',
  stfu: 'stfu',
  Seekers: 'Seekers',
  TradeCore: 'TradeCore',
  TradeCoreDemo: 'TradeCoreDemo',
  TradeDirect365: 'TradeDirect365',
  WebWallet: 'WebWallet',
  XSpotInternational: 'XSpotInternational',
  XSpotMarkets: 'XSpotMarkets',
  Zcom: 'Zcom',
  AAAFX: 'AAAFX',
  FXView: 'FXView',
  Zulu: 'Zulu',
  AvaFeatures: 'AvaFeatures',
};

