import React from 'react';
import { string, func, any } from 'prop-types';
import { Select } from 'tc-biq-design-system';

const propTypes = {
  id: string.isRequired,
  customOnChange: func.isRequired,
  actionComponent: any,
};

const defaultProps = {
  actionComponent: null,
};

const MultiSelectField = (props) => {
  const { customOnChange, id, actionComponent } = props;
  return (
    <div className="biq-form__field">
      <Select
        {...props}
        onChange={value => customOnChange({ value, id })}
        joinValues
        type="multi"
      />
      {actionComponent}
    </div>
  );
};

MultiSelectField.propTypes = propTypes;
MultiSelectField.defaultProps = defaultProps;
export default MultiSelectField;
