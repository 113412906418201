import React, { PureComponent, Fragment } from 'react';
import { object, bool, array, func, shape } from 'prop-types';
import { Space, Sidepanel, notifier, Select } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { nestedFieldsGenerator } from '../../../../../../../components/form/Components/Form';
import PaymentUtilityService from '../../../../../../../logic/services/payment-utility';
import TRANSACTION_TYPE from '../../../../../../../logic/enums/transaction-type';
import PAYMENT_CATEGORY from '../../../../../../../logic/enums/payment-category';
import PAYMENT_METHOD_TYPES from '../../../../../../../logic/enums/payment-method-types';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import connect from '../../../../../../../logic/connect';
import to from '../../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { fetchUser } from '../../../Model';
import { fetchCurrencies, fetchPaymentMethods } from './Model';
import { fetchTradingAccounts } from '../ChangeTaPassword/Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { SidepanelFooter } from '../../../../../../../components/common';
import CurrencyConverter from './CurrencyConverter';
import CardData from './CardData';
import BankData from './BankData';
import { DEFAULT_FIELDS } from './BankData/fields';
import { getTableActions, paymentConfig, internalConfig } from '../../../Sections/Transactions/table';
import appRoutes from '../../../../../../../components/App/Router/appRoutes';
import { Field, formErrorHandler, validateForm } from '../../../../../../../components/form/logic/utils';
import { convertedRateValidator } from '../../../../../../../components/form/logic/validators';
 
const env = window.config.environment;
const FORM_KEY = 'MANUAL_DEPOSIT_FORM';
const { create, resetFields, fetchFields, setFieldValue,
  setFieldsErrors, setFields, validator } = FormActionsFactory(FORM_KEY);

const fields = {
  EMAIL: 'email',
  CARD: 'card',
};

const text = {
  TITLE: gettext('Credit'),
  USED_CREDIT_CARD: gettext('Used Credit cards'),
  MANUAL_CREDIT_INFO: status => gettext(`Manual deposit finished with status ${status}`),
  SUCCESS_WITHDRAWAL: gettext('Withdrawal successfully executed.'),
  SUCCESS_DEBIT: context => gettext('Debit adjustment of {{amount}} {{currency}} executed on trading account {{accountNumber}}', context),
  SUCCESS_CREDIT: gettext('Credit successfully executed.'),
  EMAIL: gettext('Email'),
  REASON: gettext('Reason'),
  PAYMENT_GATEWAY: gettext('Payment gateway'),
  ACQUIRER: gettext('Acquirer'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
  WITHDRAWAL_REASON: gettext('Withdrawal reason'),
  GENERAL_ERROR: gettext('Something went wrong'),
};

const formModifiers = {
  withdraw_reason: {
    label: text.WITHDRAWAL_REASON,
    placeholder: text.WITHDRAWAL_REASON,
  },
  converted_amount: {
    validators: [
      convertedRateValidator(),
    ],
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const defaultState = {
  isMethodVisible: false,
  isGatewayVisible: false,
  isReasonVisible: false,
  isEmailVisible: false,
  isCardInfoVisible: false,
  isUsedCreditCardVisible: false,
  isBankInfoVisible: false,
  isAcquirerVisible: false,
  isWithdrawReasonVisible: false,
  isCancelDeposit: false,
  isAdjustment: false,
  reasons: [],
  usedCreditCardOptions: [],
  gatewayOptions: [],
  acquirerOptions: [],
  methodOptions: [],
  gateway: null,
};

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  formFields: array,
  formErrors: object,
  formValues: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
  currencies: array,
  paymentMethods: object,
  tradingAccounts: array,
  isCredit: bool,
  dispatch: func.isRequired,
  history: shape({
    push: func,
  }).isRequired,
};

const defaultProps = {
  user: null,
  actions: null,
  formFields: [],
  formErrors: null,
  formValues: null,
  isIb: false,
  currencies: [],
  paymentMethods: null,
  tradingAccounts: [],
  isCredit: true,
};

class Credit extends PureComponent {
  constructor(props) {
    super(props);
    
    const { isIb, actions } = props;
    this.actions = actions;
    this.isCurrencyConversionEnabled = isFeatureEnabled()('CURRENCY_CONVERSION');
    this.isAva = env === 'AvaTrade';
    this.isCommentsFieldVisible = env === 'ICMCapital' && !isIb;
    this.api = (user, isIb, isCredit) => getDjangoApi(`${isIb ? 'ib' : 'users'}/${user.id}/${isCredit ? 'deposit' : 'withdraw'}`);
    this.reasonApi = isCredit => getDjangoApi(`autocomplete/payment_reasons_${isCredit ? 'credit' : 'debit'}`);
    this.gatewaysApi = getDjangoApi('payment_gateways');

    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.getRequestPayload = this.getRequestPayload.bind(this);

    this.state = {
      ...defaultState,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    this.actions.fetchCurrencies();
    this.actions.fetchPaymentMethods();
    this.actions.fetchTradingAccounts(user);
  }

  componentDidUpdate(prevProps) {
    this.onChangeCategory(prevProps);
    this.onChangePaymentMethod(prevProps);
    this.generateEwalletFields();
  }

  async onSubmit() {
    const isValid = validateForm(this.actions.validator);
    if (!isValid) return;
    const { user, isIb, isCredit, formValues } = this.props;
    const { isCancelDeposit, isAdjustment } = this.state;
    const [err, result] = await to(this.actions.create(this.api(user, isIb, isCredit), this.getRequestPayload));
    err ? this.onError(err) : this.onSuccess({ isCancelDeposit, isAdjustment, ...formValues, tpiState: _.get(result, 'value.response.tpi_state') });
  }

  onSuccess(oldState) {
    const { isIb, isCredit, history } = this.props;
    const { isCancelDeposit, isAdjustment, amount, trading_account, tpiState } = oldState;
    const formatSuccessMessage = () => {
      const { currency } = trading_account || {};
      const accountNumber = _.get(trading_account, 'display_name', '').split(',')[0];
      return text.SUCCESS_DEBIT({ currency, accountNumber, amount });
    };

    this.updateTables(paymentConfig, internalConfig);
    if (!isCredit && !isCancelDeposit && !isAdjustment) {
      history.push(!isIb ? appRoutes.PENDING_WITHDRAWALS : appRoutes.IB_PENDING_WITHDRAWALS);
    } else if (isCredit) {
      notifier.info(isIb ? text.SUCCESS_CREDIT : text.MANUAL_CREDIT_INFO(tpiState));
      this.onClose({ success: true });
    } else {
      notifier.success(formatSuccessMessage());
      this.onClose({ success: true });
    }
  }

  onError(payload) {
    formErrorHandler(text.GENERAL_ERROR)(payload, () => this.onChangeErrors(payload));
  }

  onClose(response) {
    const success = response && response.success;
    const { sidepanelManager, user, isIb } = this.props;

    if (success) {
      this.actions.fetchUser({ id: user.id }, isIb);
    }

    this.setState({ ...defaultState });

    sidepanelManager.close();
  }

  onChangeCategory(prevProps) {
    const prevCategory = _.get(prevProps, 'formValues.payment_category.value');
    const category = _.get(this.props, 'formValues.payment_category.value');
    const { isCredit } = this.props;


    if (prevCategory !== category && category) {
      const transactionType = isCredit ? TRANSACTION_TYPE.CREDIT : TRANSACTION_TYPE.DEBIT;
      const isMethodVisible = PaymentUtilityService().isMethodVisible(transactionType, category);
      const isGatewayVisible = PaymentUtilityService().isGatewayVisible(transactionType, category);
      const isReasonVisible = PaymentUtilityService().isReasonVisible(transactionType, category);
      const isWithdrawReasonVisible = PaymentUtilityService().isWithdrawReasonVisible(transactionType, category);
      const isCancelDeposit = category === PAYMENT_CATEGORY.CANCELLED_DEPOSIT;
      const isAdjustment = category === PAYMENT_CATEGORY.WITHDRAWAL_ADJUSTMENT || PAYMENT_CATEGORY.ADJUSTMENT;
      this.setState({
        isMethodVisible,
        isGatewayVisible,
        isReasonVisible,
        isWithdrawReasonVisible,
        isCancelDeposit,
        isAdjustment,
      }, () => isReasonVisible && this.getChoicesForReasons(category));
    }
  }

  onChangePaymentMethod(prevProps) {
    const { paymentMethods, isCredit } = this.props;
    const prevMethod = _.get(prevProps, 'formValues.payment_method.value');
    const method = _.get(this.props, 'formValues.payment_method.value');
    const card = _.get(this.props, 'formValues.card.value');
    const { isMethodVisible } = this.state;

    if (prevMethod !== method) {
      const methodType = paymentMethods[method] && paymentMethods[method].type;

      this.setState({
        isEmailVisible: isMethodVisible && [PAYMENT_METHOD_TYPES.E_WALLET, PAYMENT_METHOD_TYPES.E_WIRE, PAYMENT_METHOD_TYPES.CRYPTO].includes(methodType),
        isCardInfoVisible: isMethodVisible && methodType === PAYMENT_METHOD_TYPES.CREDIT_CARD && (isCredit || (!isCredit && !this.isAva)),
        isUsedCreditCardVisible: isMethodVisible && methodType === PAYMENT_METHOD_TYPES.CREDIT_CARD && !isCredit && this.isAva,
        isBankInfoVisible: isMethodVisible && methodType === PAYMENT_METHOD_TYPES.WIRE_TRANSFER,
      }, () => {
        this.updateGatewayOptions(method);
        this.updateUsedCreditCardOptions(card);
      });
    }
  }

  onChangeReason = (reason) => {
    this.setPaymentReason(reason);
  }

  onChangeGateway = (gateway) => {
    const { isMethodVisible } = this.state;
    const gatewayName = _.get(gateway, 'display_name');
    const gatewayValue = _.get(gateway, 'value');
    const { isCredit, isIb } = this.props;
    
    this.setState({ 
      isAcquirerVisible: isCredit
        && isMethodVisible && gatewayName
        && gatewayName.toLowerCase().startsWith('bank') && !isIb,
    }, () => {
      this.gatewaysApi.retrieve(gateway.value).then(({ data }) => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ gateway: data });
      });
      this.setPaymentGateway(gateway);
      this.updateAcquirerOptions(gatewayValue);
    });
  }

  onChangeUsedCreditCard = (e) => {
    this.setField(fields.CARD, { value: e.value, display_name: e.display_name });
  };

  onChangeEmail(e) {
    const email = e.target.value;
    this.setField(fields.EMAIL, email);
  }

  onChangeErrors(prevProps) {
    const prevFormErrors = prevProps.formErrors;
    const { formErrors } = this.props;
    
    if (_.isEmpty(prevFormErrors) && !_.isEmpty(formErrors)) {
      const newErrors = _.reduce(formErrors, (acc, value, key) => {
        const isObject = value
          && typeof value === 'object'
          && value.constructor === Object;
        if (isObject) {
          const children = _.reduce(value, (childrenAcc, childrenValue, childrenKey) => ({
            ...childrenAcc,
            [`${key}_${childrenKey}`]: childrenValue,
          }), {});
          return { ...acc, [key]: value, ...children };
        }

        return { ...acc, [key]: value };
      }, {});

      this.actions.setFieldsErrors(newErrors);
    }
  }

  onFormReady = () => {
    this.updateMethodOptions();
  }

  getRequestPayload(values) {
    const {
      formFields,
      formValues,
      isCredit,
      paymentMethods,
    } = this.props;
    const {
      isMethodVisible,
      isGatewayVisible,
      isReasonVisible,
      isEmailVisible,
      isCardInfoVisible,
      isWithdrawReasonVisible,
      isUsedCreditCardVisible,
      isBankInfoVisible,
      isAcquirerVisible,
    } = this.state;
    const {
      is_bank_acc_details_required,
      is_card_details_required,
    } = formValues;
    const BANK_DATA_ID = 'bank_account_data';
    const nestedFieldKeys = formFields.filter(field => !!field.children).map(field => field.id);
    const getNestedKey = fieldKey => nestedFieldKeys.find(key => fieldKey.startsWith(key));
    const getFieldValue = (field = null) => (!!field && typeof field === 'object' ? field.value : field);
    const bankData = formFields.find(field => field.id === BANK_DATA_ID);
    const locationRules = bankData.children.find(field => field.id === 'location').dyn_dependent_fields;
    const request = {};

    // Prepare initial request structure
    Object.keys(values).forEach((key) => {
      const field = values[key];
      const parentKey = getNestedKey(key);
      if (parentKey) {
        const fieldKey = key.replace(`${parentKey}_`, '');
        request[parentKey] = { ...request[parentKey], [fieldKey]: getFieldValue(field) };
      } else {
        request[key] = getFieldValue(field);
      }
    });

    if (!isMethodVisible) delete request.payment_method;
    if (!isGatewayVisible) delete request.payment_gateway;
    if (!isReasonVisible) delete request.payment_reason;
    if (!isEmailVisible) delete request.ewallet_data;
    if (!isUsedCreditCardVisible) delete request.card;
    if (!isWithdrawReasonVisible) delete request.withdraw_reason;
    if (!isAcquirerVisible) delete request.payment_acquirer;
    if (!isCardInfoVisible || !is_card_details_required) {
      delete request.card_data;
    } else if (!request.card_data) {
      // This is a hack because BE expects
      // to get an empty field for some of those
      // fields in order to validate entire entity
      request.card_data = {
        card_number: '',
        cardholder_name: '',
      };
    }

    if (!isBankInfoVisible || !is_bank_acc_details_required) {
      delete request.bank_account_data;
    } else {
      const location = _.get(request, 'bank_account_data.location');
      const selectedFields = _.get(locationRules[location], 'fields') || [];
      const defaultFields = [...DEFAULT_FIELDS, 'location'].map(field => field.replace(`${BANK_DATA_ID}_`, ''));
      const allFields = [...defaultFields, ...selectedFields];
      const data = request.bank_account_data;
      request.bank_account_data = _.reduce(data, (acc, value, key) => {
        if (allFields.includes(key)) {
          return { ...acc, [key]: value };
        }
        return acc;
      }, {});
    }

    if (request.is_conversion_required) {
      request.converted_amount = this.numericApproxForAmount(request.converted_amount);
      request.original_converted_amount = this.numericApproxForAmount(request.original_converted_amount);
    } else {
      delete request.exchange_rate;
      delete request.original_converted_amount;
      delete request.original_exchange_rate;
      delete request.converted_amount;
      delete request.deposit_currency;
    }


    // This is a hack because BE expects
    // to get at least null for email if ewallet is selected
    const paymentMethod = _.get(request, 'payment_method');
    if (paymentMethod) {
      const methodType = paymentMethods[paymentMethod].type;
      if (methodType === PAYMENT_METHOD_TYPES.CRYPTO
        || methodType === PAYMENT_METHOD_TYPES.E_WALLET
        || methodType === PAYMENT_METHOD_TYPES.E_WIRE) {
        const email = _.get(request, 'ewallet_data.email');
        request.ewallet_data = {
          email: email || null,
        };
      }
    }

    if (!isCredit) {
      const currency = request.deposit_currency;
      if (currency) {
        delete request.deposit_currency;
        request.withdrawal_currency = currency;
      }

      delete request.is_conversion_required;
      delete request.is_converted_amount_changed;
      delete request.original_converted_amount;
    }

    const convertToNumber = (fields, request) => {
      fields.forEach((field) => {
        const value = request[field];
        if (value) request[field] = Number(Number(value).toFixed(5));
      });
    };

    convertToNumber(['amount', 'exchange_rate', 'original_exchange_rate'], request);

    return request;
  }

  setPaymentReason(reason) {
    this.actions.setFieldValue({
      id: 'payment_reason',
      value: reason,
    });
  }

  setPaymentGateway = (gateway) => {
    this.actions.setFieldValue({
      id: 'payment_gateway',
      value: gateway,
    });
  }

  setUsedCreditCard = (card) => {
    this.actions.setFieldValue({
      id: 'card',
      value: card,
    });
  }

  setPaymentAcquirer = (acquirer) => {
    this.actions.setFieldValue({
      id: 'payment_acquirer',
      value: acquirer,
    });
  }

  setField(id, value) {
    this.actions.setFieldValue({
      id,
      value,
    });
  }

  getChoicesForReasons(category) {
    const { isCredit } = this.props;
    this.reasonApi(isCredit)
      .list({ category, limit: 1000000 })
      .then((response) => {
        const reasons = response.data.results
          .filter(reason => reason.is_active)
          .map(({ name, id }) => ({
            display_name: name,
            value: id,
          }));

        this.setState({
          reasons,
        });
        this.setPaymentReason(reasons && reasons[0]);
      }, () => {
        notifier.error(gettext('Failed to retrieve payment reasons'));
      });
  }

  getFormConfig() {
    const { user, isIb, isCredit } = this.props;
    return {
      form: FORM_KEY,
      api: this.api(user, isIb, isCredit),
    };
  }

  getError = (key) => {
    const { formErrors } = this.props;
    const hasError = !!formErrors[key];
    const helpText = hasError ? formErrors[key][0] : null;

    return [hasError, helpText];
  }

  updateTable = (tableConfig) => {
    const { dispatch, user, isIb } = this.props;
    const actions = getTableActions(tableConfig, user.id, isIb);
    dispatch(actions.fetchTableData());
  }

  updateTables = (...configs) => {
    configs.forEach(config => this.updateTable(config));
  };

  updateMethodOptions = () => {
    const gatewayField = this.getPaymentGatewayField();
    const methodField = this.getPaymentMethodField();
    
    if (!methodField) return;

    const { options } = methodField;
    let methodOptions = options;

    if (gatewayField) {
      const { gateways_per_method } = gatewayField;
      methodOptions = options.filter(({ value }) => gateways_per_method[value]);
    }

    this.setState({
      methodOptions,
    });
  }

  updateGatewayOptions = (paymentMethodId) => {
    const gatewayField = this.getPaymentGatewayField();
    if (!gatewayField) return;
    const { gateways_per_method, options } = gatewayField;
    const optionIds = gateways_per_method[paymentMethodId];
    const gatewayOptions = optionIds
      ? options.filter(option => optionIds.includes(option.value)) 
      : [];
    this.setPaymentGateway(undefined);
    this.setState({
      gatewayOptions,
    });
  }

  updateUsedCreditCardOptions = () => {
    const { formFields } = this.props;
    const card = formFields.find(field => field.id === 'card');
    if (!card) return;

    this.setUsedCreditCard(undefined);
    this.setState({
      usedCreditCardOptions: _.get(card, 'options', []),
    });
  }

  updateAcquirerOptions = (gatewayId) => {
    const { formFields } = this.props;
    const selected = formFields.find(field => field.id === 'payment_acquirer');
    if (!selected) return;
    const { acquirers_per_gateway, options } = selected;
    const optionIds = acquirers_per_gateway[gatewayId];
    const acquirerOptions = optionIds ? options.filter(option => optionIds.includes(option.value)) : [];
    this.setPaymentAcquirer(undefined);
    this.setState({
      acquirerOptions,
    });
  }

  generateEwalletFields() {
    if (_.isEmpty(this.ewalletFields)) {
      const { formFields } = this.props;
      const EWALLET_DATA = 'ewallet_data';
      this.ewalletFields = nestedFieldsGenerator(FORM_KEY, formFields, EWALLET_DATA);
    }
  }

  getField = (name) => {
    const { formFields } = this.props;
    const selected = formFields.find(field => field.id === name);
    if (!selected) return null;
    return selected;
  }

  getPaymentGatewayField = () => this.getField('payment_gateway')

  getPaymentMethodField = () => this.getField('payment_method')

  numericApproxForAmount(amount) {
    // numeric approximation of 2nd digit set by the rules in AvaTrade,
    // check point 20 in https://tradecrowd.atlassian.net/browse/AVABIQ-443

    return _.round(Number(amount), 3);
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const {
      sidepanelManager,
      user,
      isIb,
      actions,
      submitInProgress,
      formValues,
      currencies,
      tradingAccounts,
      isCredit,
      ...options
    } = this.props;

    const {
      isMethodVisible,
      isGatewayVisible,
      isReasonVisible,
      isEmailVisible,
      isUsedCreditCardVisible,
      isCardInfoVisible,
      isBankInfoVisible,
      isAcquirerVisible,
      isWithdrawReasonVisible,
      isCancelDeposit,
      reasons,
      gatewayOptions,
      usedCreditCardOptions,
      acquirerOptions,
      methodOptions,
      gateway,
    } = this.state;

    const {
      payment_reason,
      payment_gateway,
      payment_acquirer,
      card,
    } = formValues;

    const isCurrencyConversionVisible = !isCredit ? gateway?.debit_currency_conversion : formValues.is_conversion_required || (this.isCurrencyConversionEnabled
      && !_.isEmpty(currencies)
      && !_.isEmpty(tradingAccounts)
      && formValues.trading_account
      && formValues.amount);
    const Form = this.renderForm();
    const [gatewayHasError, gatewayHelpText] = this.getError('payment_gateway');
    const [acquirerHasError, acquirerHelpText] = this.getError('payment_acquirer');
    const [usedCreditCardHasError, usedCreditCardHelpText] = this.getError('card');
    const [paymentReasonHasError, paymentReasonHelpText] = this.getError('payment_reason');

    return (
      <Sidepanel
        {...options}
        footerRender={customFooter(
          this.onSubmit,
          this.onClose,
          submitInProgress,
        )}
      >
        <Space size={16} />
        <Form
          formId={FORM_KEY}
          modifiers={formModifiers}
          onFormReady={this.onFormReady}
          renderForm={formFields => (
            <Fragment>
              {!isIb && formFields.trading_account}
              {formFields.payment_category}
              {formFields.amount}
              {isMethodVisible && (
                <Field 
                  options={methodOptions}
                >
                  {formFields.payment_method}
                </Field>
              )}
              {isCardInfoVisible && <CardData />}
              {isUsedCreditCardVisible && (
              <Fragment>
                <Select
                  label={text.USED_CREDIT_CARD}
                  onChange={this.onChangeUsedCreditCard}
                  value={card}
                  options={usedCreditCardOptions}
                  hasError={usedCreditCardHasError}
                  helpText={usedCreditCardHelpText}
                  valueKey="value"
                  labelKey="display_name"
                />
                <Space size={12} />
              </Fragment>
              )}
              {isBankInfoVisible && <BankData />}
              {isEmailVisible && this.ewalletFields.ewallet_data_email}
              {isReasonVisible && (
              <Fragment>
                <Select 
                  label={text.REASON} 
                  onChange={this.onChangeReason}
                  value={payment_reason}
                  options={reasons}
                  hasError={paymentReasonHasError}
                  helpText={paymentReasonHelpText}
                  valueKey="value"
                  labelKey="display_name"
                />
                <Space size={12} />
              </Fragment>
              )}
              {isGatewayVisible && (
              <Fragment>
                <Select 
                  label={text.PAYMENT_GATEWAY} 
                  onChange={this.onChangeGateway}
                  value={payment_gateway}
                  options={gatewayOptions}
                  hasError={gatewayHasError}
                  helpText={gatewayHelpText}
                  valueKey="value"
                  labelKey="display_name"
                />
                <Space size={12} />
              </Fragment>
              )}
              {isCurrencyConversionVisible && (
              <CurrencyConverter
                user={user}
                isIb={isIb}
                currencies={currencies}
                tradingAccounts={tradingAccounts}
                isCredit={isCredit}
              />
              )}
              {isAcquirerVisible && (
              <Fragment>
                <Select 
                  label={text.ACQUIRER} 
                  onChange={this.setPaymentAcquirer}
                  value={payment_acquirer}
                  options={acquirerOptions}
                  hasError={acquirerHasError}
                  helpText={acquirerHelpText}
                  valueKey="value"
                  labelKey="display_name"
                />
                <Space size={12} />
              </Fragment>
              )}
              {isWithdrawReasonVisible && formFields.withdraw_reason}
              {isCredit && formFields.external_id}
              {isCancelDeposit && formFields.related_payment_transaction}
              {this.isCommentsFieldVisible && formFields.comment}
            </Fragment>
          )}
        />
        <Space size={16} />
      </Sidepanel>
    );
  }
}
  

Credit.propTypes = propTypes;
Credit.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      fetchFields,
      fetchCurrencies,
      fetchPaymentMethods,
      fetchTradingAccounts,
      create,
      resetFields,
      setFieldValue,
      setFieldsErrors,
      setFields,
      validator,
    },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = ({ forms, pages }) => {
  const form = forms[FORM_KEY];
  const page = pages.USER_SINGLE;
  const action = page.actions.CREDIT;
  const taAction = page.actions.CHANGE_TA_PASSWORD;
  return {
    submitInProgress: form.submitInProgress,
    formFields: form.fields,
    formValues: form.values,
    formErrors: form.errors,
    currencies: action.currencies,
    paymentMethods: action.paymentMethods,
    tradingAccounts: taAction.accounts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Credit));
