import React, { PureComponent } from 'react';
import { notifier } from 'tc-biq-design-system';
import { object, func, string, arrayOf, bool } from 'prop-types';
import { isEmpty, forEach } from 'lodash';
import classnames from 'classnames';

import { fieldsType, segmentsType } from './FilterTypes';
import connect from '../../logic/connect';
import { setActiveFilter, setActiveFilters, resetFilterStore } from './FilterRedux';
import ViewDropdown from './ViewDropdown';
import ActiveFilters from './ActiveFilters';
import AddFilterButton from './AddFilterButton';
import SaveViewButton from './SaveViewButton';
import ViewFormSidepanel from './ViewFormSidepanel';
import RemoveSavedFilters from './RemoveSavedFilters';
import If from '../If';
import { hasAccess } from '../../logic/services/acl';
import { getDjangoApi } from '../../logic/services/api-factory';
import { flattenNestedFields } from '../../logic/services/query-adapter';
import { gettext } from '../../logic/utilities/languageUtility';
import { parseFilterKey } from './filterUtils';

import './Filters.scss';

const text = {
  DELETE_VIEW_SUCCESS: gettext('View successfully deleted'),
  DELETE_VIEW_ERROR: gettext('Error while deleting view'),
};

const propTypes = {
  fields: fieldsType,
  initialFilters: object,
  resetFilterStore: func.isRequired,
  onChangeSegment: func.isRequired,
  onFilterChange: func.isRequired,
  setActiveFilters: func.isRequired,
  setActiveFilter: func.isRequired,
  segments: segmentsType.isRequired,
  // We use this to refresh options once is segment changed
  refreshOptions: func.isRequired,
  // Required for saving segments
  view: string,
  columnsState: object,
  excluded: arrayOf(string),
  predefinedFilters: object,
  hideSegments: bool,
  tableModifier: object,
  fetchInProgress: bool,
};

const defaultProps = {
  fields: [],
  initialFilters: {},
  view: '',
  columnsState: null,
  excluded: [],
  predefinedFilters: {},
  hideSegments: false,
  tableModifier: {},
  fetchInProgress: false,
};

class Filters extends PureComponent {
  constructor(props) {
    super(props);
    this.onSegmentClick = this.onSegmentClick.bind(this);
    this.shouldShowRemoveIcon = false;
    this.state = {
      segments: {},
    };

    this.settingSegmentsApi = getDjangoApi('settings/segments');
  }

  componentDidUpdate(prevProps) {
    const { predefinedFilters } = this.props;
    this.takeFiltersFromSelectedSegment(prevProps);
    this.setPredefinedActiveFilter(prevProps.predefinedFilters, predefinedFilters);
  }

  componentWillUnmount() {
    const { resetFilterStore } = this.props;
    resetFilterStore();
  }

  onSegmentClick(segment) {
    const { onChangeSegment, onFilterChange, setActiveFilters, tableModifier } = this.props;
    this.shouldShowRemoveIcon = segment.id !== 'default';
    const predefinedFilters = this.getPredefinedFilters();
    const filters = { ...segment.filters, ...predefinedFilters };
    onChangeSegment(segment, tableModifier);
    onFilterChange(filters);
    setActiveFilters(filters);
  }

  onRemoveClick = () => {
    const { segments, refreshOptions, tableModifier } = this.props;
    this.settingSegmentsApi.destroy(segments.selectedSegment.id)
      .then(() => {
        notifier.success(text.DELETE_VIEW_SUCCESS);
        refreshOptions(tableModifier);
      }, () => {
        notifier.error(text.DELETE_VIEW_ERROR);
      });
  }

  setPredefinedActiveFilter(prevFilters, currentFilters) {
    const { setActiveFilter } = this.props;

    if (!isEmpty(currentFilters)) {
      forEach(currentFilters, (filter, key) => setActiveFilter({ key: `${key}__${filter.operator}`, value: filter.value }));
    } else if (!isEmpty(prevFilters) && isEmpty(currentFilters)) {
      forEach(prevFilters, (filter, key) => setActiveFilter({ key: `${key}__${filter.operator}` }));
    }
  }

  getVisibleFilterFields = (fields) => {
    const { excluded } = this.props;
    const active = this.getActiveFilterKeys();
    const ignored = [...excluded, ...active];

    return fields.filter(field => !ignored.includes(field.key));
  }

  getActiveFilterKeys = () => {
    const { initialFilters } = this.props;
    return Object.keys(initialFilters).map((filterKey) => {
      const { key } = parseFilterKey(filterKey);
      return key;
    });
  }

  getPredefinedFilters() {
    const { predefinedFilters } = this.props;

    const filters = {};

    forEach(predefinedFilters, (filter, key) => {
      filters[`${key}__${filter.operator}`] = filter.value; 
    });

    return filters;
  }

  modifyFields = (fields, modifiers) => fields.map((field) => {
    const fieldKey = field.key.split('__')[0];
    if (!fieldKey || !modifiers[fieldKey]) return field;
    const { filterOptions } = modifiers[fieldKey];
    return {
      ...field,
      ...(filterOptions ? { filterOptions } : {}),
    };
  })

  takeFiltersFromSelectedSegment() {
    const { segments, setActiveFilters, hideSegments } = this.props;
    const { segments: { selectedSegment } } = this.state;

    this.shouldShowRemoveIcon = segments.selectedSegment.id !== 'default';
    const prevSelectedSegmentId = _.get(selectedSegment, 'id');
    const nextSelectedSegment = segments.selectedSegment;

    if (prevSelectedSegmentId !== nextSelectedSegment.id && !hideSegments) {
      this.setState({
        segments,
      }, () => {
        const predefinedFilters = this.getPredefinedFilters();
        setActiveFilters({ ...nextSelectedSegment.filters, ...predefinedFilters });
      });
    }
  }

  render() {
    const {
      segments,
      fields,
      initialFilters,
      onFilterChange,
      view,
      onChangeSegment,
      refreshOptions,
      columnsState,
      predefinedFilters,
      hideSegments,
      tableModifier,
      fetchInProgress,
    } = this.props;

    const allFilterFields = flattenNestedFields(fields);
    const modifiedAllFilterFields = this.modifyFields(allFilterFields, tableModifier);
    const visibleFilterFields = this.getVisibleFilterFields(modifiedAllFilterFields);
    
    const activeFiltersProps = {
      fields: modifiedAllFilterFields,
      initialFilters,
      onFilterChange,
      predefinedFilters,
    };

    return (
      <div className={classnames('biq-filters', { 'biq-filters--disable': fetchInProgress })}>
        {!hideSegments && <ViewDropdown segments={segments} onSegmentClick={this.onSegmentClick} />}

        <ActiveFilters {...activeFiltersProps} />
        <AddFilterButton updateSelectedFilters={onFilterChange} fields={visibleFilterFields} />

        <If condition={(hasAccess('segments.create') || hasAccess('segments.update')) && !hideSegments}>
          <SaveViewButton selectedSegment={segments.selectedSegment} columnsState={columnsState} />
        </If>
        <If condition={this.shouldShowRemoveIcon && hasAccess('segments.settings.destroy') && !hideSegments}>
          <RemoveSavedFilters onRemoveClick={this.onRemoveClick} />
        </If>

        <ViewFormSidepanel
          columnsState={columnsState}
          selectedSegment={segments.selectedSegment}
          view={view}
          changeSegment={onChangeSegment}
          refreshOptions={refreshOptions}
          tableModifier={tableModifier}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { setActiveFilter, setActiveFilters, resetFilterStore };

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;

export default connect(
  null,
  mapDispatchToProps,
)(Filters);
